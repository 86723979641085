import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Ocean = () => {
  return (
    <Layout>
      <Head title="Ocean Vuong" />
      <h3>Ocean Vuong</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Massachusetts
      </h4>
      <p>
        <OutboundLink href="https://www.oceanvuong.com/">Website</OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://en.wikipedia.org/wiki/Ocean_Vuong">
          Wikipedia
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.instagram.com/ocean_vuong/">
          Instagram
        </OutboundLink>
      </p>
      <p>
        Vietnamese American poet, essayist, and novelist. Vuong is a recipient
        of the 2014 Ruth Lilly/Sargent Rosenberg fellowship from the Poetry
        Foundation, a 2016 Whiting Award, and the 2017 T.S. Eliot Prize for his
        poetry. His debut novel, On Earth We're Briefly Gorgeous, was published
        in 2019. He received a MacArthur Grant the same year.
      </p>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/u5NuCrAkjGw"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/ZiVvQvvIPY4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </Layout>
  );
};

export default Ocean;
